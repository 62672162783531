export const SET_ROUTES = "SET_ROUTES";
export const setRoutes = (data) => ({
    type: SET_ROUTES,
    payload: data,
});

export const SET_ROUTE = "SET_ROUTE";
export const setRoute = (data) => ({
    type: SET_ROUTE,
    payload: data,
});
