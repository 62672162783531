import isBrowser from "../utils/isBrowser";

const saveState = isBrowser()
    ? (state) => {
          try {
              const serializedState = JSON.stringify(state);

              localStorage.setItem("state", serializedState);
          } catch {
              // ignore write errors
          }
      }
    : null;

export default saveState;
