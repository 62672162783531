import isBrowser from "../utils/isBrowser";

const loadState = isBrowser()
    ? () => {
          try {
              const serializedState = localStorage.getItem("state");

              if (serializedState === null) {
                  return undefined;
              }

              return JSON.parse(serializedState);
          } catch (err) {
              return undefined;
          }
      }
    : null;

export default loadState;
