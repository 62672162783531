import * as R from "ramda";
import {
    LOG_IN,
    LOG_OUT,
    UPDATE_FAVORITES,
    UPDATE_PLACES,
    UPDATE_STARTED_ROUTES,
    UPDATE_FINISHED_ROUTES,
    UPDATE_STATS,
} from "../actions";
import createReducer from "./createReducer";

const initialState = {
    isLoggedIn: false,
    data: null,
};

export const authReducer = createReducer(
    {
        [LOG_IN]: (state, action) => {
            const { jwt, user } = action.payload;
            const hasAvatar = user.avatar && user.avatar.url;
            const hasBgImage = user.bgimage && user.bgimage.url;

            user.avatar = hasAvatar
                ? `${process.env.STRAPI_API_URL}${user.avatar.url}`
                : "";
            user.bgimage = hasBgImage
                ? `${process.env.STRAPI_API_URL}${user.bgimage.url}`
                : "";

            return R.pipe(
                R.assoc("isLoggedIn", true),
                R.assoc("data", {
                    jwt: jwt,
                    ...user,
                })
            )(state);
        },
        [LOG_OUT]: (state, _action) =>
            R.pipe(R.assoc("isLoggedIn", false), R.assoc("data", null))(state),
        [UPDATE_FAVORITES]: (state, action) =>
            R.assocPath(["data", "favorites"], action.payload, state),
        [UPDATE_STARTED_ROUTES]: (state, action) =>
            R.assocPath(["data", "started_routes"], action.payload, state),
        [UPDATE_FINISHED_ROUTES]: (state, action) =>
            R.assocPath(["data", "finished_routes"], action.payload, state),
        [UPDATE_PLACES]: (state, action) =>
            R.assocPath(["data", "places"], action.payload, state),
        [UPDATE_STATS]: (state, action) =>
            R.assocPath(["data", "stats"], action.payload, state),
    },
    initialState
);

export default authReducer;
