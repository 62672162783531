export const LOG_IN = "LOG_IN";
export const logIn = (data) => ({ type: LOG_IN, payload: data });

export const LOG_OUT = "LOG_OUT";
export const logOut = () => ({ type: LOG_OUT });

export const UPDATE_FAVORITES = "UPDATE_FAVORITES";
export const updateFavorites = (favorites) => ({
    type: UPDATE_FAVORITES,
    payload: favorites,
});

export const UPDATE_STARTED_ROUTES = "UPDATE_STARTED_ROUTES";
export const updateStartedRoutes = (routes) => ({
    type: UPDATE_STARTED_ROUTES,
    payload: routes,
});

export const UPDATE_FINISHED_ROUTES = "UPDATE_FINISHED_ROUTES";
export const updateFinishedRoutes = (routes) => ({
    type: UPDATE_FINISHED_ROUTES,
    payload: routes,
});

export const UPDATE_PLACES = "UPDATE_PLACES";
export const updatePlaces = (places) => ({
    type: UPDATE_PLACES,
    payload: places,
});

export const UPDATE_STATS = "UPDATE_STATS";
export const updateStats = (stats) => ({
    type: UPDATE_STATS,
    payload: stats,
});
