import * as R from "ramda";
import { SET_ROUTES, SET_ROUTE } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    routes: [],
};

const routesReducer = createReducer(
    {
        [SET_ROUTE]: (state, action) => {
            const _routes = state.routes.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

            return R.assoc("routes", _routes, state);
        },
        [SET_ROUTES]: (state, action) =>
            R.assoc("routes", action.payload, state),
    },
    initialState
);

export default routesReducer;
