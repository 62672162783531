import * as R from "ramda";
import { HIDE_DECLARATION_MODAL, SHOW_DECLARATION_MODAL } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

const declarationModalReducer = createReducer(
    {
        [HIDE_DECLARATION_MODAL]: (state, _action) =>
            R.assoc("visible", false, state),
        [SHOW_DECLARATION_MODAL]: (state, _action) =>
            R.assoc("visible", true, state),
    },
    initialState
);

export default declarationModalReducer;
