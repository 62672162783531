import * as R from "ramda";
import { HIDE_RANKS_MODAL, SHOW_RANKS_MODAL } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

const ranksModalReducer = createReducer(
    {
        [HIDE_RANKS_MODAL]: (state, _action) =>
            R.assoc("visible", false, state),
        [SHOW_RANKS_MODAL]: (state, _action) => R.assoc("visible", true, state),
    },
    initialState
);

export default ranksModalReducer;
