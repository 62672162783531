export const HIDE_REGISTER_MODAL = "HIDE_REGISTER_MODAL";
export const hideRegisterModal = () => ({ type: HIDE_REGISTER_MODAL });

export const SHOW_REGISTER_MODAL = "SHOW_REGISTER_MODAL";
export const showRegisterModal = () => ({ type: SHOW_REGISTER_MODAL });

export const SETURL_REGISTER_MODAL = "SETURL_REGISTER_MODAL";
export const setUrlRegisterModal = (url) => ({
    type: SETURL_REGISTER_MODAL,
    payload: url,
});
