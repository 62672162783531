import * as R from "ramda";
import { NORMAL_FONT, BIGGER_FONT, BIGGEST_FONT } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    fontSize: 62.5,
};

export const accessibilityReducer = createReducer(
    {
        [NORMAL_FONT]: (state, _action) => R.assoc("fontSize", 62.5, state),
        [BIGGER_FONT]: (state, _action) => R.assoc("fontSize", 66, state),
        [BIGGEST_FONT]: (state, _action) => R.assoc("fontSize", 70, state),
    },
    initialState
);

export default accessibilityReducer;
