import {
    createStore as reduxCreateStore,
    combineReducers,
    compose,
    applyMiddleware,
} from "redux";

// REDUCERS
import routeReducer from "./reducers/routeReducer";
import routesReducer from "./reducers/routesReducer";
import authReducer from "./reducers/authReducer";
import headerReducer from "./reducers/headerReducer";
import bottomNavReducer from "./reducers/bottomNavReducer";
import sideDrawerReducer from "./reducers/sideDrawerReducer";
import strapiReducer from "./reducers/strapiReducer";
import registerModalReducer from "./reducers/registerModalReducer";
import ranksModalReducer from "./reducers/ranksModalReducer";
import aboutModalReducer from "./reducers/aboutModalReducer";
import declarationModalReducer from "./reducers/declarationModalReducer";
import accessibilityReducer from "./reducers/accessibilityReducer";
import locationDeniedModalReducer from "./reducers/locationDeniedModalReducer";

import loadState from "./loadState";

const persistedState = loadState();

const rootReducer = combineReducers({
    route: routeReducer,
    routes: routesReducer,
    auth: authReducer,
    header: headerReducer,
    bottomNav: bottomNavReducer,
    sideDrawer: sideDrawerReducer,
    strapi: strapiReducer,
    registerModal: registerModalReducer,
    ranksModal: ranksModalReducer,
    aboutModal: aboutModalReducer,
    declarationModal: declarationModalReducer,
    accessibility: accessibilityReducer,
    locationDeniedModal: locationDeniedModalReducer,
});

const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const createStore = () =>
    reduxCreateStore(
        rootReducer,
        persistedState,
        composeEnhancers(applyMiddleware())
    );

export default createStore;
