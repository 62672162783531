import * as R from "ramda";
import { SET_CURRENT_ROUTE } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    currentRoute: 0,
};

const routeReducer = createReducer(
    {
        [SET_CURRENT_ROUTE]: (state, action) =>
            R.assoc("currentRoute", action.payload, state),
    },
    initialState
);

export default routeReducer;
