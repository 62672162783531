import * as R from "ramda";
import { STRAPI_SET_ABOUT, STRAPI_SET_GLOBAL } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    global: null,
    about: null,
};

const strapiReducer = createReducer(
    {
        [STRAPI_SET_GLOBAL]: (state, action) =>
            R.assoc("global", action.payload, state),
        [STRAPI_SET_ABOUT]: (state, action) =>
            R.assoc("about", action.payload, state),
    },
    initialState
);

export default strapiReducer;
