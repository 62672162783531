import * as R from "ramda";
import {
    HIDE_REGISTER_MODAL,
    SHOW_REGISTER_MODAL,
    SETURL_REGISTER_MODAL,
} from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
    continueUrl: {},
};

const registerModalReducer = createReducer(
    {
        [HIDE_REGISTER_MODAL]: (state, _action) =>
            R.assoc("visible", false, state),
        [SHOW_REGISTER_MODAL]: (state, _action) =>
            R.assoc("visible", true, state),
        [SETURL_REGISTER_MODAL]: (state, _action) =>
            R.assoc("continueUrl", _action.payload.url, state),
    },
    initialState
);

export default registerModalReducer;
