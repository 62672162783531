import * as R from "ramda";
import {
    HIDE_LOCATION_DENIED_MODAL,
    SHOW_LOCATION_DENIED_MODAL,
} from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

const locationDeniedModalReducer = createReducer(
    {
        [HIDE_LOCATION_DENIED_MODAL]: (state, _action) =>
            R.assoc("visible", false, state),
        [SHOW_LOCATION_DENIED_MODAL]: (state, _action) =>
            R.assoc("visible", true, state),
    },
    initialState
);

export default locationDeniedModalReducer;
