import * as R from "ramda";
import { HIDE_ABOUT_MODAL, SHOW_ABOUT_MODAL } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

const aboutModalReducer = createReducer(
    {
        [HIDE_ABOUT_MODAL]: (state, _action) =>
            R.assoc("visible", false, state),
        [SHOW_ABOUT_MODAL]: (state, _action) => R.assoc("visible", true, state),
    },
    initialState
);

export default aboutModalReducer;
