import * as R from "ramda";
import {
    HIDE_BOTTOM_NAV,
    SHOW_BOTTOM_NAV,
    TOGGLE_BOTTOM_NAV,
} from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

const bottomNavReducer = createReducer(
    {
        [HIDE_BOTTOM_NAV]: (state, _action) => R.assoc("visible", false, state),
        [SHOW_BOTTOM_NAV]: (state, _action) => R.assoc("visible", true, state),
        [TOGGLE_BOTTOM_NAV]: (state, _action) =>
            R.over(R.lensProp("visible"), R.not, state),
    },
    initialState
);

export default bottomNavReducer;
