import React from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from "react-redux";
import throttle from "lodash.throttle";

import createStore from "./src/state/createStore";
import saveState from "./src/state/saveState";

export const wrapWithProvider = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore();

    store.subscribe(
        throttle(() => {
            saveState({
                auth: store.getState().auth,
            });
        }, 1000)
    );

    return (
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY} language="pl">
            {element}
            </GoogleReCaptchaProvider>
        </Provider>
    );
};

export default wrapWithProvider;
