import * as R from "ramda";
import { HIDE_HEADER, SHOW_HEADER, TOGGLE_HEADER } from "../actions";
import createReducer from "./createReducer";

const initialState = {
    visible: false,
};

export const headerReducer = createReducer(
    {
        [HIDE_HEADER]: (state, _action) => R.assoc("visible", false, state),
        [SHOW_HEADER]: (state, _action) => R.assoc("visible", true, state),
        [TOGGLE_HEADER]: (state, _action) =>
            R.over(R.lensProp("visible"), R.not, state),
    },
    initialState
);

export default headerReducer;
